import { loadScript } from '@iheartradio/web.utilities';
import type { EventTypeMap } from './types';
import type { TrackerConfig } from '@iheartradio/web.signal';

const GLASSBOX_REPORT_URI =
  'https://report.iheartmedia.gbqofs.io/reporting/f7304291-d400-47f1-93a9-60adc7460d00/cls_report';

const GLASSBOX_DETECTOR_LOCATION =
  'https://cdn.gbqofs.com/iheartmedia/detector-dom.min.js';

const GLASSBOX_DETECTOR_LOCATION_DEV =
  'https://cdn.gbqofs.com/iheartmedia/u/detector-dom.min.js';

const glassbox = (
  enabled: boolean,
  isDev: boolean,
): TrackerConfig<EventTypeMap> => ({
  enabled,
  name: 'Glassbox',
  initialize: async () => {
    await loadScript(
      isDev ? GLASSBOX_DETECTOR_LOCATION_DEV : GLASSBOX_DETECTOR_LOCATION,
      {
        async: true,
        'data-clsconfig': `reportURI=${GLASSBOX_REPORT_URI}`,
        id: '_cls_detector',
      },
    );
  },

  events: () => {},
});

export default glassbox;
